import { PropsWithChildren, useLayoutEffect, useRef, useState } from 'react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import { Icon } from '@iconify/react'
import chevronDown from '@iconify/icons-heroicons/chevron-down'
import chevronUp from '@iconify/icons-heroicons/chevron-up'

interface TruncatedTextProps extends PropsWithChildren {
  lineCount: keyof typeof LineClamp
  className?: string
}

const LineClamp = {
  1: 'tw-line-clamp-1',
  2: 'tw-line-clamp-2',
  3: 'tw-line-clamp-3',
  4: 'tw-line-clamp-4',
  5: 'tw-line-clamp-5',
  6: 'tw-line-clamp-6',
}

export default function TruncatedText({
  lineCount,
  className,
  children,
}: Readonly<TruncatedTextProps>) {
  const [clientHeight, setClientHeight] = useState<number | undefined>()
  const [scrollHeight, setScrollHeight] = useState<number | undefined>()
  const [expanded, setExpanded] = useState(false)
  const ref = useRef<HTMLParagraphElement>(null)

  useLayoutEffect(() => {
    setClientHeight(ref.current?.clientHeight)
    setScrollHeight(ref.current?.scrollHeight)
  }, [ref.current?.clientHeight, ref.current?.scrollHeight])
  const displayShowMoreButton =
    !expanded && clientHeight && scrollHeight && clientHeight < scrollHeight
  const displayShowLessButton = expanded

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <p
        ref={ref}
        className={twMerge(expanded ? '' : LineClamp[lineCount], className)}
      >
        {children}
      </p>
      {(displayShowMoreButton || displayShowLessButton) && (
        <div>
          <button
            onClick={() => {
              setExpanded(!expanded)
            }}
            className="tw-inline-flex tw-items-center tw-gap-0.5 tw-font-medium tw-text-gray-900 tw-underline"
          >
            {displayShowMoreButton && (
              <>
                Show more
                <Icon icon={chevronDown} />
              </>
            )}
            {displayShowLessButton && (
              <>
                Show less
                <Icon icon={chevronUp} />
              </>
            )}
          </button>
        </div>
      )}
    </div>
  )
}
