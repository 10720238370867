import { FilterDrawerFilterDef } from './FiltersDrawerButton.tsx'
import { FilterGroupState } from '@/components/core/table/filters/useFilterGroupState.ts'
import ActiveFilterPill from '@/components/core/drawers/filter/ActiveFilterPill.tsx'

interface ActiveFiltersProps<T> {
  readonly filterDefs: Array<FilterDrawerFilterDef<T>>
  readonly appliedFilters: FilterGroupState
  readonly onClearFilter: (key: string) => void
  readonly onClearAll: () => void
}

export function ActiveFilters<T>({
  filterDefs,
  appliedFilters,
  onClearFilter,
  onClearAll,
}: ActiveFiltersProps<T>) {
  return (
    <div data-testid="active-filters">
      <div className="tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">
        Active filters:
      </div>
      <div className="tw-inline-flex tw-flex-row tw-flex-wrap tw-items-center tw-gap-2">
        {filterDefs.map(filterDef => {
          switch (filterDef.type) {
            case 'faceted':
            case 'date-range': {
              const appliedFilter = appliedFilters.map.get(filterDef.key)
              if (appliedFilter?.isActive) {
                return (
                  <ActiveFilterPill
                    key={filterDef.key}
                    label={filterDef.header}
                    text={appliedFilter.text}
                    onClear={() => {
                      onClearFilter(filterDef.key)
                    }}
                  />
                )
              }
              break
            }
            case 'quick-filters':
              return filterDef.filters.map(f => {
                const appliedFilter = appliedFilters.map.get(f.key)
                if (appliedFilter?.isActive) {
                  return (
                    <ActiveFilterPill
                      key={f.key}
                      text={f.label}
                      onClear={() => {
                        onClearFilter(f.key)
                      }}
                    />
                  )
                }
              })
          }
        })}
        <button
          type="button"
          className="tw-rounded-full tw-border tw-border-slate-300 tw-bg-white tw-px-3 tw-py-1 tw-text-xs tw-font-medium tw-text-blue-600 hover:tw-bg-gray-200"
          onClick={onClearAll}
        >
          Clear all
        </button>
      </div>
    </div>
  )
}
