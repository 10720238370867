import { useClientProfileId } from '@/contexts/ClientProfileHooks.ts'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import {
  ButtonBaseStyles,
  ButtonColor,
  ButtonSize,
} from '@/components/core/buttons/ButtonStyles.ts'
import Divider from '@/components/layout/Divider.tsx'

/**
 * Temporary solution until we build the actual dispatch approval feature. Gated to just EverBright since
 * the Google doc link is specific to them.
 */
export default function QuoteActionSection() {
  const clientProfileId = useClientProfileId()
  if (clientProfileId !== 'Everbright') return <></>
  const className = twMerge(
    ButtonBaseStyles,
    ButtonSize.default,
    ButtonColor.default,
    'tw-block tw-w-full tw-text-center'
  )

  return (
    <>
      <Divider />
      <div>
        <a
          target="_blank"
          href="https://docs.google.com/spreadsheets/d/1RyTutwhjK8-tcj4kS3dQ0RgiUbJCRt64/edit?gid=2066530905#gid=2066530905"
          rel="noreferrer"
          className={className}
        >
          Action in spreadsheet
        </a>
      </div>
    </>
  )
}
