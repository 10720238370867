import { Drawer } from '@/components/core/drawers/Drawer.tsx'
import React, { useState } from 'react'
import Button from '@/components/core/buttons/Button.tsx'
import filterListIcon from '@iconify/icons-material-symbols/filter-list'
import { Portal } from '@headlessui/react'
import { RowData } from '@tanstack/table-core'
import { DateRangePreset } from '@/components/core/datepickers/DateRangeSelector.tsx'
import { FilterDrawerDateRangeSection } from './FilterDrawerDateRangeSection.tsx'
import { FilterDrawerFacetSection } from './FilterDrawerFacetSection.tsx'
import { FacetFilterValue } from '@/components/core/table/filters/facetFilterFn.ts'
import { DateRangeFilterValue } from '@/components/core/table/filters/dateRangeFilterFn.ts'
import {
  FilterGroupState,
  useFilterGroupState,
} from '@/components/core/table/filters/useFilterGroupState.ts'
import { NestedKeyOf } from '@/helpers/TypeHelpers.ts'
import FilterDrawerQuickFiltersSection from '@/components/core/drawers/filter/FilterDrawerQuickFiltersSection.tsx'

type FilterDrawerSectionType = 'faceted' | 'date-range' | 'quick-filters'

export interface FilterDrawerFilterDefBase<T> {
  type: FilterDrawerSectionType
  key: NestedKeyOf<T>
  header: string
}

export interface FilterDrawerFacetFilterDef<T>
  extends FilterDrawerFilterDefBase<T> {
  type: 'faceted'
  rowDataAccessor: (rowData: T) => string | null
  sortingFn?: (a: string, b: string) => number
}

export interface FilterDrawerDateRangeFilterDef<T>
  extends FilterDrawerFilterDefBase<T> {
  type: 'date-range'
  presets: DateRangePreset[]
}

export interface FilterDrawerQuickFiltersDef<T> {
  type: 'quick-filters'
  // Only exists for React key purposes
  key: string
  filters: FilterDrawerBooleanFilterDef<T>[]
}

interface FilterDrawerBooleanFilterDef<T> {
  key: NestedKeyOf<T>
  label: string
}

export type FilterDrawerFilterDef<T> =
  | FilterDrawerFacetFilterDef<T>
  | FilterDrawerDateRangeFilterDef<T>
  | FilterDrawerQuickFiltersDef<T>

interface FiltersDrawerButtonProps<TData extends RowData> {
  filterDefs: Array<FilterDrawerFilterDef<TData>>
  rowData: Array<TData> | undefined
  appliedFilters: FilterGroupState
  onApplyFilters: (newFilters: FilterGroupState) => void
  title: string
  disabled?: boolean
}

export function FiltersDrawerButton<T>({
  filterDefs,
  rowData,
  appliedFilters,
  disabled,
  onApplyFilters,
  title,
}: Readonly<FiltersDrawerButtonProps<T>>): React.JSX.Element {
  const [showDrawer, setShowDrawer] = useState(false)

  function onClose() {
    setShowDrawer(false)
  }

  return (
    <>
      <Button
        size="sm"
        color="light"
        icon={filterListIcon}
        disabled={!!disabled}
        onClick={() => {
          setShowDrawer(true)
        }}
      >
        Filters
      </Button>
      <Portal>
        <Drawer show={showDrawer} onClose={onClose} data-testid="filter-drawer">
          <FilterDrawerContent
            filterDefs={filterDefs}
            rowData={rowData}
            appliedFilters={appliedFilters}
            onApplyFilters={onApplyFilters}
            onClose={onClose}
            title={title}
          />
        </Drawer>
      </Portal>
    </>
  )
}

interface FilterDrawerProps<TData extends RowData> {
  filterDefs: Array<FilterDrawerFilterDef<TData>>
  rowData: Array<TData> | undefined
  appliedFilters: FilterGroupState
  onApplyFilters: (newFilters: FilterGroupState) => void
  onClose: () => void
  title: string
}

function FilterDrawerContent<T>({
  filterDefs,
  rowData,
  appliedFilters,
  onApplyFilters,
  onClose,
  title,
}: Readonly<FilterDrawerProps<T>>) {
  const [filtersState, filtersStateActions] = useFilterGroupState(
    appliedFilters.map
  )

  return (
    <>
      <Drawer.Header onClose={onClose}>
        <h5 className="tw-uppercase tw-text-slate-500">{title}</h5>
      </Drawer.Header>
      <Drawer.Body>
        {filterDefs.map(filter => {
          switch (filter.type) {
            case 'faceted':
              return (
                <FilterDrawerFacetSection
                  key={filter.key}
                  filterDef={filter}
                  rowData={rowData}
                  filterValue={
                    filtersState.map.get(filter.key) as FacetFilterValue
                  }
                  onStateChange={state => {
                    filtersStateActions.updateFilter(filter.key, state)
                  }}
                />
              )
            case 'date-range':
              return (
                <FilterDrawerDateRangeSection
                  key={filter.key}
                  filterDef={filter}
                  filterValue={
                    filtersState.map.get(filter.key) as DateRangeFilterValue
                  }
                  onStateChange={state => {
                    filtersStateActions.updateFilter(filter.key, state)
                  }}
                />
              )
            case 'quick-filters':
              return (
                <FilterDrawerQuickFiltersSection
                  key={filter.key}
                  filtersDef={filter}
                  filtersGroupState={filtersState}
                  onStateChange={(key, value) => {
                    filtersStateActions.updateFilter(key, value)
                  }}
                />
              )
          }
        })}
      </Drawer.Body>
      <Drawer.Footer>
        <Button
          block={true}
          color="light"
          onClick={filtersStateActions.clearAllFilters}
        >
          Clear all
        </Button>
        <Button
          block={true}
          onClick={() => {
            onApplyFilters(filtersState)
            onClose()
          }}
        >
          Apply filters
        </Button>
      </Drawer.Footer>
    </>
  )
}
