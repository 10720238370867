import { Case } from '@/generated'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import Alert from '@/components/core/alerts/Alert.tsx'
import {
  DateTimeFormat,
  formatLocalDate,
} from '@/helpers/NativeDateTimeHelpers.ts'

type CaseDetailsContentProps = {
  caseData: Case
}

export default function CaseBanners({
  caseData,
}: Readonly<CaseDetailsContentProps>) {
  const { closedAt, customerUnresponsiveDate, resolutionCode } = caseData
  const { isResidentialClient } = useFeatureFlags()
  const showUnresponsiveBanner = isResidentialClient && customerUnresponsiveDate
  const showClosedBanner = closedAt !== null
  if (!showUnresponsiveBanner && !showClosedBanner) return

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      {showClosedBanner && (
        <Alert color="green">
          Closed{' '}
          {resolutionCode && (
            <>
              with Resolution Code{' '}
              <span className="tw-font-bold">{resolutionCode}</span>
            </>
          )}{' '}
          on {DateTimeFormat.format(new Date(closedAt))}
        </Alert>
      )}
      {showUnresponsiveBanner && (
        <Alert color="yellow">
          <span className="tw-text-base tw-font-bold">
            Customer Unresponsive: {formatLocalDate(customerUnresponsiveDate)}
          </span>
        </Alert>
      )}
    </div>
  )
}
