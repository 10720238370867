import { Maybe } from '@/helpers/TypeHelpers.ts'
import { FilterDataBase } from '@/components/core/table/filters/filterData.ts'
import { Row, RowData } from '@tanstack/react-table'

export type BooleanFilterValue = Maybe<BooleanFilterData>

export interface BooleanFilterData extends FilterDataBase {
  type: 'boolean'
}

export function booleanFilterData(enabled: boolean): BooleanFilterData {
  return {
    type: 'boolean',
    isActive: enabled,
  }
}

export function booleanFilterFn<TData extends RowData>(
  row: Row<TData>,
  columnId: string,
  filterValue: BooleanFilterValue
): boolean {
  if (filterValue?.isActive) {
    const value = row.getValue<Maybe<boolean>>(columnId)
    return !!value
  } else {
    return true
  }
}
