import { useMemo } from 'react'
import { PageName, ResidentialServiceActivity } from '@/generated'
import { CsvColumnOption } from '@/components/export/TableDataExportCsvContextProvider.tsx'
import { toExcelCsvDateTimeFormat } from '@/helpers/MomentHelpers.ts'

export default function useResidentialServiceActivityCsvColumns(
  pageName?: PageName
) {
  return useMemo<CsvColumnOption<ResidentialServiceActivity>[]>(() => {
    const columns: CsvColumnOption<ResidentialServiceActivity>[] = []

    if (pageName === PageName.ServiceActivity) {
      columns.push({ key: 'customerName', header: 'Customer Name' })
      columns.push({ key: 'clientAssetId', header: 'Client Asset ID' })
      columns.push({ key: 'assetId', header: 'Omnidian Asset ID' })
    }

    columns.push(
      { key: 'id', header: 'Ticket ID' },
      {
        key: 'createdAt',
        header: 'Ticket Created Date',
        accessorFn: r => toExcelCsvDateTimeFormat(r.createdAt),
      },
      {
        key: 'updatedAt',
        header: 'Last Updated',
        accessorFn: r => toExcelCsvDateTimeFormat(r.updatedAt),
      },
      { key: 'priority', header: 'Ticket Priority' },
      { key: 'status', header: 'Ticket Status' },
      { key: 'type', header: 'Ticket Type' },
      { key: 'subject', header: 'Ticket Subject' },
      { key: 'creationReason', header: 'Creation Reason' },
      {
        key: 'pendingClientAction',
        header: 'Pending Client Action',
        accessorFn: val => (val.pendingClientAction ? 'YES' : 'NO'),
      } as CsvColumnOption<ResidentialServiceActivity>
    )

    if (pageName === PageName.ServiceActivity) {
      columns.push({
        key: 'otherOpenServiceActivitiesCount',
        header: 'Count of Other Tickets Open for Asset',
      })
    }

    columns.push({ key: 'summary', header: 'Case Summary Notes' })

    return columns
  }, [pageName])
}
