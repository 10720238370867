import { Icon } from '@iconify/react/offline'
import iconClose from '@iconify/icons-heroicons/x-mark'

type Props = {
  label?: string
  text?: string
  onClear: () => void
}

export default function ActiveFilterPill({
  label,
  text,
  onClear,
}: Readonly<Props>) {
  return (
    <div className="tw-inline-flex tw-items-center tw-rounded-full tw-bg-gray-100 tw-px-3 tw-py-1">
      {label && (
        <span className="tw-whitespace-nowrap tw-text-xs tw-font-medium tw-text-gray-500">
          {label}:&nbsp;
        </span>
      )}
      {text && (
        <span className="tw-text-xs tw-font-medium tw-text-gray-900">
          {text}
        </span>
      )}
      <button
        type="button"
        className="tw-ml-2 tw-rounded-lg tw-bg-transparent tw-text-sm tw-text-gray-600 hover:tw-bg-gray-200 hover:tw-text-gray-900"
        aria-label="Clear"
        onClick={onClear}
      >
        <span className="tw-sr-only">Clear</span>
        <Icon icon={iconClose} className="tw-size-4" aria-hidden="true" />
      </button>
    </div>
  )
}
