import { FilterDataBase } from './filterData.ts'
import { DateFormat } from '@/helpers/NativeDateTimeHelpers.ts'
import { parseIsoDate } from '@/helpers/MomentHelpers.ts'
import moment from 'moment/moment'
import { DateRangePreset } from '@/components/core/datepickers/DateRangeSelector.tsx'
import { Maybe } from '@/helpers/TypeHelpers.ts'
import { Row, RowData } from '@tanstack/react-table'

export type DateRangeFilterValue = Maybe<DateRangeFilterData>

export interface DateRangeFilterData extends FilterDataBase {
  type: 'date-range'
  selection: Maybe<DateRangeFilterSelection>
  startDate: Maybe<moment.Moment>
  endDate: Maybe<moment.Moment>
}

export type DateRangeFilterSelection =
  | DateRangeFilterPresetSelection
  | DateRangeFilterCustomSelection

export type DateRangeFilterPresetSelection = {
  type: 'preset'
  preset: DateRangePreset
}

export type DateRangeFilterCustomSelection = {
  type: 'custom'
}

export function presetDateRangeFilterData(
  preset: DateRangePreset
): DateRangeFilterData {
  return {
    type: 'date-range',
    isActive: true,
    text: preset.name,
    selection: {
      type: 'preset',
      preset,
    },
    startDate: parseIsoDate(preset.isoStartDate),
    endDate: parseIsoDate(preset.isoEndDate),
  }
}

export function customDateRangeFilterData(
  startDate: Maybe<moment.Moment>,
  endDate: Maybe<moment.Moment>
): DateRangeFilterData {
  const validatedStartDate = startDate?.isValid() ? startDate : null
  const validatedEndDate = endDate?.isValid() ? endDate : null
  return {
    type: 'date-range',
    isActive: !!validatedStartDate || !!validatedEndDate,
    text: dateRangeText(validatedStartDate, validatedEndDate),
    selection: {
      type: 'custom',
    },
    startDate: validatedStartDate,
    endDate: validatedEndDate,
  }
}

function dateRangeText(
  startDate: Maybe<moment.Moment>,
  endDate: Maybe<moment.Moment>
): string | undefined {
  if (startDate && endDate) {
    return `${DateFormat.format(startDate.toDate())} to ${DateFormat.format(
      endDate.toDate()
    )}`
  } else if (startDate && !endDate) {
    return `After ${DateFormat.format(startDate.toDate())}`
  } else if (!startDate && endDate) {
    return `Before ${DateFormat.format(endDate.toDate())}`
  }
  return undefined
}

/**
 * A filter function that checks if the value of a cell exists within
 * the columns filterState.
 * @param row the table row
 * @param columnId the id of the column within the row
 * @param filterValue the current filter state
 */
export function dateRangeFilterFn<TData extends RowData>(
  row: Row<TData>,
  columnId: string,
  filterValue: DateRangeFilterValue
): boolean {
  if (filterValue?.isActive) {
    const value = row.getValue<Maybe<string>>(columnId)
    if (!value) {
      return false
    }
    const time = moment(value).startOf('day')
    if (!time.isValid()) {
      return false
    }
    const startDatePasses =
      !filterValue.startDate || time.isSameOrAfter(filterValue.startDate)
    const endDatePasses =
      !filterValue.endDate || time.isSameOrBefore(filterValue.endDate)
    return startDatePasses && endDatePasses
  } else {
    return true
  }
}
