import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  ClientProfileContext,
  ClientProfileContextValue,
} from '@/contexts/ClientProfileContext.ts'
import { AuthContext } from '@/contexts/auth/AuthContext.ts'

export const clientProfileStorageKey = 'client-profile-id'

let pendoInitialized = false

type PendoOptions = {
  visitor: {
    id: string
  }
  account: {
    id: string
  }
}

declare global {
  interface Window {
    pendo: {
      initialize: (opts: PendoOptions) => void
      identify: (opts: PendoOptions) => void
    }
    heap: {
      identify: (id: string) => void
      addUserProperties: (props: object) => void
    }
    FS: (fn: string, props: object) => void
  }
}

export function ClientProfileProvider(
  props: Readonly<React.PropsWithChildren>
): React.JSX.Element {
  const [activeClientProfileId, setActiveClientProfileId] = useState<
    string | undefined
  >(sessionStorage.getItem(clientProfileStorageKey) ?? undefined)
  const authContext = useContext(AuthContext)

  useEffect(() => {
    if (authContext.userEmail && activeClientProfileId) {
      const pendoFn = pendoInitialized
        ? window.pendo.identify
        : window.pendo.initialize
      pendoFn({
        visitor: {
          id: `dev:${authContext.userEmail}`,
        },
        account: {
          id: activeClientProfileId,
        },
      })
      pendoInitialized = true
      window.heap.identify(authContext.userEmail)
      window.heap.addUserProperties({ client: activeClientProfileId })
      window.FS('setIdentity', {
        uid: authContext.userEmail,
        properties: {
          client: activeClientProfileId,
        },
      })
    }
  }, [authContext.userEmail, activeClientProfileId])
  useEffect(() => {
    if (activeClientProfileId) {
      sessionStorage.setItem(clientProfileStorageKey, activeClientProfileId)
    } else {
      sessionStorage.removeItem(clientProfileStorageKey)
    }
  }, [activeClientProfileId])

  const contextValue = useMemo(
    (): ClientProfileContextValue => ({
      activeClientProfileId: activeClientProfileId,
      setActiveClientProfileId: (id?: string) => {
        setActiveClientProfileId(id)
      },
    }),
    [activeClientProfileId]
  )

  return (
    <ClientProfileContext.Provider value={contextValue}>
      {props.children}
    </ClientProfileContext.Provider>
  )
}
