import { twMerge } from '@/helpers/CustomTwMerge.ts'

export type QuoteLineItemProps = {
  label: string
  value: string
  className?: string
}

export default function QuoteLineItem({
  label,
  value,
  className,
}: Readonly<QuoteLineItemProps>) {
  return (
    <div
      className={twMerge(
        'tw-flex tw-items-center tw-justify-between',
        className
      )}
    >
      <div className="tw-text-gray-700">{label}</div>
      <div className="tw-font-medium tw-text-gray-900">{value}</div>
    </div>
  )
}
