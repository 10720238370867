import { FilterDrawerQuickFiltersDef } from '@/components/core/drawers/filter/FiltersDrawerButton.tsx'
import { NestedKeyOf } from '@/helpers/TypeHelpers.ts'
import { FilterGroupState } from '@/components/core/table/filters/useFilterGroupState.ts'
import {
  booleanFilterData,
  BooleanFilterData,
  BooleanFilterValue,
} from '@/components/core/table/filters/booleanFilterFn.ts'

interface Props<T> {
  filtersDef: FilterDrawerQuickFiltersDef<T>
  filtersGroupState: FilterGroupState
  onStateChange: (key: NestedKeyOf<T>, value: BooleanFilterData) => void
}

export default function FilterDrawerQuickFiltersSection<T>({
  filtersDef,
  filtersGroupState,
  onStateChange,
}: Readonly<Props<T>>) {
  return (
    <div className="tw-mb-6 tw-rounded-lg tw-border tw-border-gray-200 tw-px-6 tw-py-4">
      <h4 className="tw-mb-5 tw-font-bold tw-text-gray-900">Quick Filters</h4>
      <div className="tw-flex tw-flex-col tw-gap-3">
        {filtersDef.filters.map(filter => {
          const state = filtersGroupState.map.get(
            filter.key
          ) as BooleanFilterValue
          const onToggle = (checked: boolean) => {
            onStateChange(filter.key, booleanFilterData(checked))
          }
          return (
            <div
              key={filter.key}
              className="tw-flex tw-items-center tw-text-sm tw-text-gray-700"
            >
              <input
                id={filter.key}
                type="checkbox"
                className="tw-mr-2 tw-cursor-pointer tw-rounded tw-border-gray-200 tw-bg-gray-100 tw-text-blue-600 focus:tw-ring-2 focus:tw-ring-blue-500"
                onChange={e => {
                  onToggle(e.target.checked)
                }}
                checked={state?.isActive ?? false}
              />
              <label className="tw-grow tw-cursor-pointer" htmlFor={filter.key}>
                {filter.label}
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )
}
