import { WorkOrderQuote } from '@/generated'
import iconTimer from '@iconify/icons-material-symbols/timer-outline'
import iconCalendar from '@iconify/icons-material-symbols/calendar-month'
import { Badge } from '@/components/core/badges/Badge.tsx'
import { Icon } from '@iconify/react'
import { PercentNumberFormat } from '@/helpers/NumberFormats.ts'
import { formatLocalDate } from '@/helpers/NativeDateTimeHelpers.ts'
import Divider from '@/components/layout/Divider.tsx'
import QuoteLineItem from '@/features/serviceactivity/detailsDrawer/quote/QuoteLineItem.tsx'
import QuoteActionSection from '@/features/serviceactivity/detailsDrawer/quote/QuoteActionSection.tsx'

type WorkOrderQuoteProps = {
  quote: WorkOrderQuote
}

export default function WorkOrderQuoteContent({
  quote,
}: Readonly<WorkOrderQuoteProps>) {
  return (
    <div className="tw-flex tw-flex-col tw-gap-6 tw-rounded-lg tw-border tw-border-gray-200 tw-p-6">
      <div className="tw-flex tw-items-center tw-justify-between">
        <h3 className="tw-text-lg tw-font-medium tw-text-gray-900">
          Quote Details
        </h3>
        {/* TODO: CLIENT-312: Need the actual logic based on quote status */}
        <Badge color="red" size="large">
          <Icon icon={iconTimer} className="tw-mr-1" />
          <span className="tw-font-medium">Pending Approval</span>
        </Badge>
      </div>
      <div className="tw-text-2xl tw-font-bold tw-text-gray-900">
        {quote.clientTotalCost}
      </div>
      <Divider />
      <div className="tw-flex tw-flex-col tw-gap-2">
        <QuoteLineItem label="Mobilization" value={quote.mobilizationCost} />
        <QuoteLineItem label="Labor" value={quote.laborCost} />
        <QuoteLineItem label="Travel" value={quote.travelCost} />
        <QuoteLineItem label="Equipment" value={quote.equipmentCost} />
        <QuoteLineItem label="Material" value={quote.materialCost} />
        <QuoteLineItem label="Other" value={quote.otherCost} />
        <QuoteLineItem label="Tax" value={quote.taxCost} />
        <QuoteLineItem
          label={`Mark-Up (${PercentNumberFormat().positive.format(quote.omnidianMarkupPercentage)})`}
          value={quote.omnidianMarkupAmount}
        />
        <QuoteLineItem
          label="Total Cost"
          value={quote.clientTotalCost}
          className="tw-pt-2 tw-text-lg tw-font-bold tw-text-gray-900"
        />
      </div>
      <Divider />
      <div className="tw-flex tw-items-center">
        <Icon icon={iconCalendar} className="tw-mr-2 tw-size-4" />
        <span className="tw-font-medium tw-text-gray-900">
          Expiration Date:&nbsp;
        </span>
        {quote.expirationDate && (
          <span className="tw-text-gray-700">
            {formatLocalDate(quote.expirationDate)}
          </span>
        )}
      </div>
      <QuoteActionSection />
    </div>
  )
}
