import { Maybe } from '@/helpers/TypeHelpers.ts'
import moment from 'moment/moment'

export const ISO_DATE_FORMAT = 'YYYY-MM-DD'
export const ISO_MONTH_FORMAT = 'YYYY-MM'
export const DATE_INPUT_FORMAT = 'M/D/YYYY'

export function parseRequiredIsoDate(value: string): moment.Moment {
  const parsedValue = moment(value, ISO_DATE_FORMAT, true).startOf('day')
  if (!parsedValue.isValid())
    throw new Error(`Invalid ISO date value: ${value}`)
  return parsedValue
}

export function parseIsoDate(
  value: Maybe<string>,
  throwIfInvalid: boolean = true
): Maybe<moment.Moment> {
  if (!value) return null
  const parsedValue = moment(value, ISO_DATE_FORMAT, true).startOf('day')
  if (throwIfInvalid && !parsedValue.isValid())
    throw new Error(`Invalid ISO date value: ${value}`)
  return parsedValue.isValid() ? parsedValue : null
}

export function parseIsoMonth(value: Maybe<string>): Maybe<moment.Moment> {
  if (!value) return null
  const parsedValue = moment(value, ISO_MONTH_FORMAT, true)
    .startOf('day')
    .startOf('month')
  if (!parsedValue.isValid())
    throw new Error(
      `Invalid month ${value}. Month should be in ISO format ${ISO_MONTH_FORMAT}`
    )
  return parsedValue
}

type MonthlyBucket = {
  startDate: moment.Moment
  endDate: moment.Moment
  isFullMonth: boolean
}

export function parseMonthlyBucket(
  isoStartDate: string,
  isoEndDate: string
): Maybe<MonthlyBucket> {
  const startDate = parseIsoDate(isoStartDate)
  const endDate = parseIsoDate(isoEndDate)
  if (
    !startDate ||
    !endDate ||
    startDate.month() !== endDate.month() ||
    startDate.year() != endDate.year()
  ) {
    console.error('Invalid monthly bucket dates', startDate, endDate)
    return null
  }
  return {
    startDate,
    endDate,
    isFullMonth:
      startDate.date() === 1 && endDate.date() === endDate.daysInMonth(),
  }
}

// Microsoft Excel doesn't handle timestamps with timezones very well. There is no definitive
// string format, including ISO_8601, that Excel will recognize as a timestamp instead of a general
// text string. Product would like the CSV export to have timestamps which are recognized by
// Excel so that means not including the timezone info.
const EXCEL_CSV_TIMESTAMP_FORMAT: string = 'YYYY-MM-DD HH:mm:ss'

export const toExcelCsvDateTimeFormat = (dateTimeString: string) => {
  return moment(dateTimeString).format(EXCEL_CSV_TIMESTAMP_FORMAT)
}

export const toExcelCsvDateFormat = (dateString: string) => {
  return moment(dateString).format(ISO_DATE_FORMAT)
}

export const calcDaysBetween = (
  startDate: string,
  endDate?: string
): number => {
  return moment(endDate).diff(moment(startDate), 'days')
}
